@font-face {
    font-family: 'Cera Pro';
    src: url('CeraPro-Regular.eot');
    src: local('Cera Pro Regular'), local('CeraPro-Regular'),
        url('CeraPro-Regular.eot?#iefix') format('embedded-opentype'),
        url('CeraPro-Regular.woff') format('woff'),
        url('CeraPro-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

